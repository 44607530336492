.Quantity {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid var(--border);
  padding: 0rem;
  border-radius: 0.25rem;
  // align-items: center;
  justify-content: center;
  background: transparent;

  &__wrapper {
    display: grid;
    gap: 0.25rem;
  }

  &__label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--text-secondary);
    text-transform: uppercase;
  }

  &__input {
    width: 35px;
    text-align: center;
    border: none;
    outline: none;
    font-weight: 600;
    appearance: none;
    font-size: 0.75rem;
    font-family: "Montserrat", sans-serif;
    background: transparent;
    color: var(--primary);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__minus {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: 0;
    background: none;
    border-right: 1px solid var(--border);
    margin: 0;
  }

  &__plus {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: 0;
    background: none;
    border-left: 1px solid var(--border);
  }
}
