@mixin ipad {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}
