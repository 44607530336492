@import "@/scss/responsive";

.cookie {
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 500px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 20px;
  align-items: center;
  color: var(--primary);

  @include mobile {
    // width: 50%;
    grid-template-columns: 5fr 1fr;
    grid-gap: 20px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  p {
    line-height: 1.6;
    font-size: 12px;

    @include mobile {
      // font-size: 11px;
    }

    a {
      color: inherit;
      text-decoration-line: underline;
      text-decoration-color: lighten(#5e5e67, 40%);
      font-weight: 600;
    }
  }

  strong {
    font-weight: 600;
  }

  &__button-area {
    display: flex;
    justify-content: flex-end;

    @include mobile {
      justify-content: flex-start;
    }
  }

  &__button {
    background: var(--primary);
    color: #fff;
    font-size: 12px;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    @include mobile {
      // font-size: 11px;
      width: 100%;
      text-align: center;
    }
  }
}
