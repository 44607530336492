.secret {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;
  padding: 20px;
  // background: var(--background);
}

.form {
  width: 100%;
  // max-width: 400px;
  // padding: 2rem;
  background: #fff;
  // border-radius: 8px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__buttons {
    margin-top: 1.5rem;
  }
}
