@import "_reset.scss";

:root {
  --primary: #225a41;
  --primary-hover: #164b34;
  --secondary: #daebe4;
  --tertiary: #ff9900;
  --text: #225a41;
  --text-secondary: #417660;
  --text-negative: #fff;
  --background: #fff;
  --background-gray: #ededed;
  --border: #ddd;
}

html {
  height: 100vh;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  font-size: var(--font-size);
  background-color: var(--color-white);
  font-family: var(--font-montserrat), sans-serif;
}

p.paragraph {
  margin: auto;
  max-width: 75%;
  line-height: 1.75;

  &:last-child {
    margin-bottom: 60px;
  }
}

.pinned {
  display: none;
}

.not-pinned {
  display: block;
}

.is-pinned {
  width: 78px;
  height: 78px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;

  .pinned {
    display: block;
  }

  .not-pinned {
    display: none;
  }
}

.slick-dots {
  bottom: 1rem !important;

  li {
    button {
      &::before {
        color: #fff !important;
        font-size: 0.75rem !important;
      }
    }
  }
}
