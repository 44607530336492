@import "@/scss/responsive";

.whatsapp {
  gap: 8px;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  position: fixed;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 1rem 1rem;
  align-items: center;
  border-radius: 3rem;
  justify-content: center;
  color: var(--text-negative);
  background-color: var(--primary-hover);
  text-decoration: none;
  z-index: 1000;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: var(--text-secondary);
  }
}

.show {
  @include mobile {
    bottom: 6rem;
  }
}
