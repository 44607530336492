.grid {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  // padding-bottom: 3rem;
}
