@import "@/scss/responsive.scss";

.ProductDetail {
  width: 100%;
  background: var(--background-gray);

  &__thumbnails {
    // display: "flex",
    // gap: "0.5rem",
    // paddingBottom: "1rem",

    display: flex;
    gap: 0.5rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  &__copyMessage {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    color: var(--primary);
  }

  &__badges {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // position: absolute;
    // top: 0.5rem;
    // left: 2rem;

    span {
      font-size: 0.7rem;
      font-weight: 500;
      letter-spacing: 0.05em;
      padding: 0.3rem 0.75rem 0.2rem 0.75rem;
      border-radius: 2rem;
      border: 1px solid var(--primary);
      color: var(--primary);
      text-transform: uppercase;
    }
  }

  &__content {
    position: relative;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    line-height: normal;
    cursor: pointer;

    a {
      color: var(--primary);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      text-decoration: none;
    }
  }
  &__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
  &__image {
    position: relative;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      order: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__main_image {
    opacity: 0;
    transition: opacity 1s ease;
    &__loaded {
      opacity: 1;
    }
  }

  &__content {
    padding: 4rem 2rem;
    align-self: start;
    display: grid;
    gap: 1rem;

    @include mobile {
      padding: 1rem;
      order: 1;
    }
  }

  &__categories {
    display: flex;
    gap: 0.5rem;
    // margin-bottom: 1rem;

    a {
      font-size: 0.8rem;
      color: var(--primary);
      text-transform: uppercase;
      font-weight: 400;
      text-decoration: none;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--primary);
    line-height: 1.2;
    letter-spacing: 0.05em;
    font-family: var(--font-mirador);
  }

  &__sku {
    font-size: 0.9rem;
    color: var(--primary);
  }

  &__price {
    font-size: 0.9rem;
    color: var(--primary);
    font-weight: 600;
  }

  &__dimensions {
    font-size: 0.9rem;
    color: var(--text-secondary);
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__relations {
    margin-top: 2rem;
    border-top: 1px solid var(--secondary);
    padding-top: 2rem;
  }

  &__relationsTitle {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__relatedWrapper {
    display: flex;
    justify-content: center;
  }

  &__related {
    display: grid;
    width: 60%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    place-items: center;
    gap: 1rem;

    @include mobile {
      width: 100%;
    }
  }

  &__description {
    color: var(--text-secondary);
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0.5rem 0;
    line-height: 1.5;
    * {
      font-weight: 400 !important;
      line-height: 1.6 !important;
      margin-bottom: 0.5rem !important;
    }
  }

  &__wishlist {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: var(--primary);
    font-size: 0.8rem;
  }

  &__secondary_actions {
    display: flex;
    align-content: center;
    gap: 1rem;
    margin-top: 1rem;

    a {
      font-size: 0.8rem;
    }
  }

  &__alreadyOnCart {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    color: var(--text-secondary);
  }
}
