.Select {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  position: relative;

  &__label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--text-secondary);
    text-transform: uppercase;
  }

  &__select {
    max-width: 250px;
    border: 1px solid var(--text-secondary);
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--primary);
    appearance: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    font-family: var(--font-montserrat), sans-serif;

    background: url(/images/chevron.svg) no-repeat right 1rem center/0.8rem;
  }
}
