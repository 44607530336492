.inputGroup {
  flex: auto;
  position: relative;

  label {
    top: -0.325rem;
    left: 0.5rem;
    padding: 0 0.3rem;
    font-size: 0.7rem;
    position: absolute;
    color: var(--primary);
    text-transform: uppercase;
    background-color: var(--background);
    font-weight: 500;
    z-index: 1;
  }

  input {
    width: 100%;
    padding: 0.75rem 1rem;
    // border-radius: 3rem;
    border-radius: 0.25rem;
    color: var(--primary);
    border: 1px solid var(--primary);
    font-weight: 500;
    font-family: var(--font-montserrat), sans-serif;
    background-color: #fff;
    appearance: none;

    &:focus {
      outline: none;
      border-color: var(--primary);
      border-width: 1px;
      box-shadow: inset 0 0 0.25rem 0.2rem rgba(#225a41, 0.015);
    }
  }

  small {
    font-size: 0.65rem;
    color: red;
    width: 100%;
    text-align: left;
    padding-left: 1.25rem;
    margin-top: 0.5rem;
    display: block;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
  }

  .passwordToggle {
    position: absolute;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
}
