.Loading {
  text-align: center;
  margin: 3rem;
  gap: 0.5rem;
  color: var(--text-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
