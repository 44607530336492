.inputGroup {
  flex: auto;
  position: relative;

  label {
    top: -0.325rem;
    left: 1rem;
    padding: 0 0.3rem;
    font-size: 0.75rem;
    position: absolute;
    color: var(--primary);
    text-transform: uppercase;
    background-color: var(--background);
    font-weight: 500;
  }

  select {
    width: 100%;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    border-radius: 0.25rem;
    color: var(--primary);
    border: 1px solid var(--primary);
    appearance: none;
    font-weight: 500;
    background: url(/images/chevron.svg) no-repeat right 1rem center/0.8rem;
    font-family: var(--font-montserrat), sans-serif;

    &:focus {
      outline: none;
      border-color: var(--primary);
      border-width: 1px;
      box-shadow: 0 0 0 0.2rem rgba(#225a41, 0.1);
    }
  }

  small {
    font-size: 0.65rem;
    color: red;
    width: 100%;
    text-align: left;
    padding-left: 1.25rem;
    margin-top: 0.5rem;
    display: block;
  }
}
