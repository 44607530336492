.idwall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(50px);
  overflow-y: hidden;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    padding: 2rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    // margin-top: 6rem;
    background-color: #fff;
    border-radius: 1rem;
  }

  &__logo {
    text-align: center;
    margin-bottom: 1rem;

    img {
      width: 80px;
      height: auto;
    }
  }

  &__welcome {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.115rem;
    font-weight: 500;
    color: #225a41;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &_row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    &_row_three {
      display: grid;
      grid-template-columns: 1.2fr 1.2fr 0.8fr; // Proporções ajustadas para cada campo
      gap: 1rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    button {
      max-width: 200px;
      width: 100%;
    }
  }

  &__back_link {
    font-size: 0.75rem;
    color: #225a41;

    &:hover {
      color: #225a41;
      text-decoration: underline;
    }
  }

  &__description {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1rem;
  }

  &__progress {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_bar {
      width: 100%;
      height: 4px;
      background: #e0e0e0;
      border-radius: 2px;
      overflow: hidden;

      &_fill {
        height: 100%;
        background: #225a41;
        transition: width 0.3s ease;
      }
    }

    &_label {
      text-align: center;
      color: #999;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}

.review {
  &__section {
    background: rgba(0, 0, 0, 0.03);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;

    h3 {
      color: #225a41;
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 0.5rem;
      font-size: 0.95rem;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }
  }
}

.loading {
  text-align: center;
  padding: 1rem;
  color: #225a41;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.95rem;
    animation: pulse 1.5s infinite;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
