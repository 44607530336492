@import "@/scss/responsive";

.footer {
  margin-top: auto;
  padding: 3rem 0 2rem;
  color: var(--primary);
  background-color: #f5f5f5;
  // border-top: 4px solid var(--primary);

  &__container {
    gap: 2rem;
    margin: auto;
    display: flex;
    padding: 0 2rem;
    max-width: 1600px;
    flex-direction: column;

    @include mobile {
      text-align: center;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  &__grid {
    gap: 1rem;
    display: grid;
    font-size: 0.8rem;
    padding-bottom: 2rem;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @include mobile {
      grid-template-columns: 1fr;
      text-align: center;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        align-self: center;
        justify-self: center;
      }
    }
  }
}

.column {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    font-weight: 400;
    position: relative;
    font-size: 1rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--font-mirador);
  }

  &__content {
    line-height: 2;
    font-size: 0.75rem;
    text-transform: uppercase;

    h5 {
      font-size: 0.65rem;
      letter-spacing: 1px;
    }

    a,
    a:visited {
      text-decoration: none;
      color: var(--primary);
    }
  }

  &__social {
    line-height: 2;
    font-size: 0.75rem;
    text-transform: uppercase;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr;

    @include mobile {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: center;
    }

    h5 {
      font-size: 0.65rem;
      letter-spacing: 1px;
    }

    a,
    a:visited {
      text-decoration: none;
      color: var(--primary);
    }
  }
}

.linkSocial {
  gap: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  + a {
    margin-top: 10px;

    @include mobile {
      margin-top: 0;
    }
  }
}

.copyright {
  gap: 1rem;
  margin: auto;
  display: flex;
  font-size: 0.75rem;
  max-width: 640px;
  text-align: center;
  line-height: 1.5;
  align-items: center;
  justify-self: center;
  flex-direction: column;

  h4 {
    font-weight: 600;
  }
}

.alcance {
  gap: 10px;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  text-decoration: none;
  font-size: 0.65rem;
  color: #aaa;

  span {
    color: #aaa;
  }

  &:visited {
    color: var(--primary);
  }
}

.policy {
  gap: 1rem;
  display: flex;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;

  a,
  a:visited {
    font-weight: 500;
    text-transform: uppercase;
    color: var(--primary);
    text-underline-offset: 5px;
    text-decoration-color: var(--text-secondary);
    text-decoration-thickness: 2px;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
  }
}
