.badge {
  top: -2px;
  right: -2px;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 0.65rem;
  position: absolute;
  border-radius: 15px;
  display: inline-block;
  color: var(--text-negative);
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  top: 0;
}
