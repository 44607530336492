@import "@/scss/responsive";

.header {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @include mobile {
    display: flex;
  }
}

.logo {
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }
}

.button {
  position: relative;
  // padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  // background-color: rgba(0, 0, 0, 0.025);
  border-radius: 5rem;
  color: var(--primary);
}

.buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  z-index: 999;
  transition: all 0.3s;
  color: var(--primary);

  &__item {
    font-weight: 600;
  }

  &__categories {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 0.75rem;
    font-size: 0.75rem;
    column-count: 2;

    a {
      color: var(--primary);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      line-height: 1.2;
      padding-bottom: 15px;
      display: block;
    }
  }

  &__small {
    font-size: 0.75rem;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--font-mirador);
  text-transform: uppercase;
  color: var(--primary);
  text-decoration: none;
  letter-spacing: 0.1rem;
  font-size: 0.95em;
  line-height: 1;
  font-weight: 600;
}

.busca {
  display: flex !important;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
  // text-decoration: none;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.account {
  display: flex !important;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  color: var(--primary);
  font-size: 0.85em;
}

.account_container {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}
.buttonCircle,
.buttonGretting {
  padding: 12px;
  position: relative;
  border-radius: 30px;
  display: inline-flex;
  place-content: center;
  text-decoration: none;
  color: var(--text);
  // border: 1px solid #ddd;
  // background-color: rgba(0, 0, 0, 0.025);

  svg {
    width: 24px;
    height: 24px;
  }
}

.buttonGretting {
  gap: 5px;
  padding: 12px 12px;
  align-items: center;

  &__info {
    font-size: 0.7rem;
    line-height: 1.05;
    letter-spacing: 0.05em;

    h5 {
      font-weight: 400;
      display: block;
      color: var(--text);
    }

    p {
      color: var(--text);
      font-weight: 600;
    }
  }
}

.showroom {
  font-family: var(--font-mirador);
  font-weight: 600;
}
