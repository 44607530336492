@import "/src/scss/responsive.scss";

.modal {
  border: 0;
  padding: 0;
  width: 70%;
  z-index: 100;
  background-color: #fff;
  // padding: 1rem;
  // border-radius: 1rem;
  border-radius: 0.25rem;
  border: 2px solid #fff;
  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.3);
  max-width: 1024px;

  @include mobile {
    width: calc(100% - 20px);
  }

  &[open] {
    animation: show 0.2s ease;
  }

  &[open]::backdrop {
    background: rgba(255, 255, 255, 0.7);
    animation: backdrop-show 0.2s ease;
  }

  &.closed {
    animation: hide 0.2s ease;
  }

  &.closed::backdrop {
    animation: backdrop-hide 0.2s ease;
  }
}

.close {
  top: 10px;
  width: 32px;
  right: 10px;
  height: 32px;
  border: none;
  z-index: 300;
  position: absolute;
  background-color: transparent;
  cursor: pointer;

  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  // border: 1px solid var(--text-secondary);

  &:hover {
    background: #fff;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes backdrop-show {
  from {
    background: transparent;
  }
  to {
    background: rgba(255, 255, 255, 0.7);
  }
}

@keyframes backdrop-hide {
  from {
    background: rgba(255, 255, 255, 0.7);
  }
  to {
    background: transparent;
  }
}
