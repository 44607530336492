.button {
  background-color: var(--primary);
  color: #fff;
  padding: 0.7rem 1.8rem;
  border: none;
  // border-radius: 50px;
  cursor: pointer;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 0.95rem;
  letter-spacing: 0.05em;
  border: 1px solid var(--border);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-montserrat), sans-serif;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  line-height: normal;
  border-radius: 0.25rem;
}

.spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
