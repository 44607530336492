@import "@/scss/responsive";

.header {
  padding: 15px 0;
  position: sticky;
  top: 0px;
  color: var(--text);
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9998;
  backdrop-filter: blur(20px);

  &__empty {
    width: 0;
  }

  @include mobile {
    display: none;
  }

  &__container {
    margin: auto;
    padding: 0 30px;
    max-width: 1600px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
      position: absolute;
      right: 0;
      background: #fff;
      width: 100%;
      grid-template-columns: 1fr;
    }
  }
}

.brand {
  gap: 25px;
  display: flex;
  align-items: center;

  nav {
    gap: 2rem;
    display: flex;
    align-items: center;

    @include mobile {
      gap: 1rem;
      flex-direction: column;
    }
  }
}

.logo {
  bottom: 0;
  left: 50%;
  width: 220px;
  height: 120px;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);

  background-image: linear-gradient(
    to right,
    transparent 0%,
    #fff 33%,
    #fff 66%,
    transparent 100%
  );

  @include mobile {
    display: none;
  }
}

.navigation {
  padding: 12px 0;
  position: relative;
  color: var(--text);
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 42px;

  @include mobile {
    display: none;
  }

  &__container {
    margin: auto;
    padding: 0 30px;
    max-width: 1600px;

    display: grid;
    grid-template-columns: 1fr auto auto;

    nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        font-weight: 500;
      }

      @include mobile {
        flex-direction: column;
      }
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}

.link {
  gap: 5px;
  font-size: 0.8rem;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--text);

  + .link {
    margin-left: 30px;
  }
}

.button {
  gap: 10px;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 12px 0;
  border-radius: 30px;
  letter-spacing: 1px;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--text);
  // background-color: rgba(0, 0, 0, 0.025);

  &__text {
    background-color: transparent;
  }

  svg {
    width: 22px;
    height: 22px;
  }
}

.buttonGroup {
  gap: 10px;
  display: flex;
  margin-left: auto;
  align-items: center;
}

.buttonCircle,
.buttonGretting {
  padding: 12px;
  position: relative;
  border-radius: 30px;
  display: inline-flex;
  place-content: center;
  text-decoration: none;
  color: var(--text);
  // border: 1px solid #ddd;
  // background-color: rgba(0, 0, 0, 0.025);

  svg {
    width: 24px;
    height: 24px;
  }
}

.buttonGretting {
  gap: 5px;
  padding: 12px 12px;
  align-items: center;

  &__info {
    font-size: 0.7rem;
    line-height: 1.05;
    letter-spacing: 0.05em;

    h5 {
      font-weight: 400;
      display: block;
      color: var(--text);
    }

    p {
      color: var(--text);
      font-weight: 600;
    }
  }
}
