@import "@/scss/responsive.scss";

.submenu {
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  background-color: var(--primary-hover);

  &__container {
    margin: auto;
    // display: grid;
    // columns
    column-count: 5;
    line-height: 1.2;

    gap: 1rem;
    padding: 2rem;
    max-width: 1600px;
    position: relative;
    // align-items: center;
    // grid-template-rows: repeat(5, 1fr);
    // grid-template-columns: repeat(5, 1fr);

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      text-align: center;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      column-count: 1;
    }

    &::after {
      width: 0;
      height: 0;
      top: -10px;
      left: 51px;
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--primary-hover) transparent;
    }
  }

  &__link {
    font-size: 0.9rem;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--text-negative);
    width: 100%;
    display: block;
    padding-bottom: 15px;
    padding-left: 0;

    &:visited {
      color: var(--text-negative);
    }
  }
}
